import React from "react";
import { Container } from "react-bootstrap";
import Layout from "../components/Layout";
import Cart from "../components/Cart/Cart";

const CartPage = () => (
  <Layout>
    <Container fluid="md" className="pt-5">
      <h1 className="text-center">Shopping Cart</h1>
      <div style={{ borderBottom: "solid white 2px" }} className="pt-3"></div>

      <Cart />
    </Container>
  </Layout>
);

export default CartPage;
