import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { Link } from "gatsby";
import StoreContext from "../context/StoreContext";
import LineItem from "./LineItem";

const Cart = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext);

  const handleCheckout = () => {
    window.open(checkout.webUrl);
  };

  const lineItems = checkout.lineItems.map((item) => <LineItem key={item.id.toString()} item={item} />);
  console.log(lineItems);
  return (
    <div className="shopping-cart-grid">
      <div>
        {lineItems.length > 0 ? (
          lineItems
        ) : (
          <span>
            The cart is empty.
            <br />
            <br /> Check out our <Link to="/cut-out-lines">Cut-Out Lines</Link> or <Link to="/gear">Gear</Link>.
          </span>
        )}
      </div>
      <div className="totals">
        <h2>Subtotal</h2>
        <p>$ {checkout.subtotalPrice}</p>
        <br />
        <h2>Taxes</h2>
        <p>$ {checkout.totalTax}</p>
        <br />
        <h2>Total</h2>
        <p>$ {checkout.totalPrice}</p>
        <br />
        <Button variant="success" onClick={handleCheckout} disabled={checkout.lineItems.length === 0}>
          Check out
        </Button>
      </div>
    </div>
  );
};

export default Cart;
