import React, { useContext } from "react";
import { Link } from "gatsby";
import { Button } from "react-bootstrap";
import StoreContext from "../context/StoreContext";

const LineItem = (props) => {
  const { item } = props;
  const {
    removeLineItem,
    store: { client, checkout },
  } = useContext(StoreContext);

  const variantImage = item.variant.image ? (
    <img src={item.variant.image.src} alt={`${item.title} product shot`} height="60px" />
  ) : null;

  const SelectedOptions = () => {
    if (item.variant.selectedOptions) {
      return item.variant.selectedOptions.map((option) =>
        // filter values with "default title", which happens when products have no variants
        option.value !== "Default Title" ? `${option.name}: ${option.value} ` : null
      );
    } else return null;
  };

  const handleRemove = () => {
    removeLineItem(client, checkout.id, item.id);
  };

  return (
    <div className="line-items">
      <Link to={`/product/${item.variant.product.handle}/`}>{variantImage}</Link>
      <Link to={`/product/${item.variant.product.handle}/`}>
        <p>{item.title}</p>
      </Link>
      <p>
        <SelectedOptions />
      </p>
      <h5>Quantity: {item.quantity}</h5>
      <Button variant="dark" size="sm" onClick={handleRemove} className="ml-4">
        Remove
      </Button>
    </div>
  );
};

export default LineItem;
